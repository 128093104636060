/* Dashboard.css */

.dashboard-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.dashboard-header {
  margin-bottom: 2rem;
  color: #333;
  font-size: 1.8rem;
}

.user-email {
  font-size: 1rem;
  color: #666;
  margin-left: 0.5rem;
}

.dashboard-info {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.dashboard-info p {
  margin: 10px 0;
  font-size: 1.2rem;
  color: #555;
}

.logout-button {
  display: block;
  margin: 2rem auto 0;
  padding: 0.8rem 2rem;
  background: #dc3545;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;
}

.logout-button:hover {
  background: #c82333;
}

.referral-code-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
  text-align: center;
  margin-bottom: 2rem;
}

.referral-code-section p {
  color: #666;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.referral-info {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.referral-code-section,
.referred-by-section {
  margin-bottom: 20px;
  text-align: center;
}

.referral-code-section p,
.referred-by-section p {
  color: #666;
  margin-bottom: 10px;
  font-size: 0.9rem;
}

.referred-by-section .referral-code {
  background-color: #f0f4f8;
  border-color: #ccd;
}

.referral-code-section {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 20px;
  text-align: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.referral-header {
  margin-bottom: 20px;
}

.referral-header h3 {
  margin: 0;
  color: #333;
  font-size: 1.4rem;
}

.referral-subtitle {
  color: #666;
  margin: 0.5rem 0 1.5rem;
}

.referral-code {
  background: #f8f9fa;
  padding: 1rem;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s;
  font-size: 1.1rem;
  color: #333;
}

.referral-code:hover {
  background: #edf2ff;
}

.copy-icon {
  font-size: 1.2rem;
}

.copy-status {
  color: #2c7be5;
  font-size: 0.9rem;
}

.referred-by-section {
  margin-top: 2rem;
  text-align: center;
}

.bonus-claimed {
  margin-top: 1rem;
  color: #28a745;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

.bonus-icon {
  font-size: 1.2rem;
}

.loading, .error {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #dc3545;
}

.no-logs {
  text-align: center;
  color: #666;
  padding: 2rem;
  background: #f5f5f5;
  border-radius: 8px;
}

/* Section Selector Styles */
.dashboard-content {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.section-selector {
  padding: 1.5rem;
  border-bottom: 1px solid #eee;
}

.section-dropdown {
  width: 100%;
  max-width: 300px;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  color: #333;
  background-color: #f8f9fa;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  cursor: pointer;
  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;
}

.section-dropdown:focus {
  outline: none;
  border-color: #2c7be5;
  box-shadow: 0 0 0 2px rgba(44, 123, 229, 0.2);
}

.section-content {
  padding: 2rem;
}

/* Section Navigation */
.section-navigation {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 1rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.nav-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: flex-start;
  gap: 1.5rem;
  border: 2px solid transparent;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-height: 100px;
}

.nav-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-color: #e2e8f0;
}

.nav-card.active {
  border-color: #2c7be5;
  background: #f8faff;
}

.nav-icon {
  font-size: 2rem;
  color: #2c7be5;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f0f7ff;
  border-radius: 12px;
  flex-shrink: 0;
}

.nav-info {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.nav-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2c7be5;
}

.nav-details {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.detail-item {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #666;
  font-size: 0.95rem;
}

.detail-label {
  color: #666;
  font-weight: 500;
}

.detail-value {
  color: #333;
  font-weight: 600;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .section-navigation {
    grid-template-columns: 1fr;
  }

  .nav-card {
    padding: 1.25rem;
    min-height: auto;
  }

  .nav-icon {
    width: 40px;
    height: 40px;
    font-size: 1.5rem;
  }

  .nav-title {
    font-size: 1.1rem;
  }

  .detail-item {
    font-size: 0.9rem;
  }
}

/* Overview Section */
.overview-section .user-stats {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.stat-card {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  min-width: 150px;
  text-align: center;
  flex: 1;
}

@media (max-width: 768px) {
  .overview-section .user-stats {
    justify-content: center;
  }
  
  .stat-card {
    flex: 0 1 200px;
  }
}

.stat-card h3 {
  margin: 0;
  color: #666;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.stat-card .stat-value {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  color: #2c7be5;
}

/* Activity Logs Section */
.activity-logs-section {
  max-height: 600px;
  overflow-y: auto;
}

.activity-log-item {
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: #fafafa;
}

.log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.log-type {
  font-weight: 600;
  color: #666;
  text-transform: capitalize;
  background: #e8e8e8;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.log-timestamp {
  color: #888;
  font-size: 0.9rem;
}

.log-changes {
  margin-top: 0.5rem;
}

.points-change, .coins-change {
  margin: 0.3rem 0;
  font-size: 0.95rem;
}

.previous-value {
  color: #888;
}

.new-value {
  color: #2c7be5;
  font-weight: 600;
}

/* Referral Section */
.referral-section {
  max-width: 600px;
  margin: 0 auto;
}

.referral-code-section {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.referral-header {
  margin-bottom: 1.5rem;
  text-align: center;
}

.referral-header h3 {
  margin: 0;
  color: #333;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.referral-subtitle {
  color: #666;
  margin: 0;
  font-size: 1rem;
}

.referral-url-container {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  margin-bottom: 1rem;
}

.referral-url {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  font-size: 0.95rem;
  color: #2c7be5;
  background: #f8faff;
  outline: none;
  cursor: text;
}

.copy-button {
  align-self: center;
  padding: 0.75rem 2rem;
  background: #2c7be5;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  min-width: 120px;
}

.copy-status {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  background: #333;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.8rem;
  animation: fadeIn 0.2s ease-out;
  white-space: nowrap;
}

@media (max-width: 768px) {
  .copy-button {
    width: 100%;
    min-width: unset;
  }
}

.share-hint {
  text-align: center;
  color: #666;
  margin: 0;
  font-size: 0.9rem;
}

.referred-by-section {
  margin-top: 2rem;
  padding: 1.5rem;
  background: #f8f9fa;
  border-radius: 12px;
}

.referred-by-section p {
  margin: 0;
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
}

.bonus-claimed {
  margin-top: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #38a169;
  font-size: 0.9rem;
}

.bonus-icon {
  font-size: 1.2rem;
}

@media (max-width: 768px) {
  .referral-code-section {
    padding: 1.5rem;
  }

  .referral-url-container {
    flex-direction: column;
  }

  .copy-button {
    width: 100%;
  }

  .copy-status {
    position: static;
    display: inline-block;
    transform: none;
    margin-left: 0.5rem;
  }
}

/* Tab Styles */
.dashboard-tabs {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  margin-bottom: 2rem;
}

.tab-buttons {
  display: flex;
  border-bottom: 1px solid #eee;
  padding: 0 1rem;
}

.tab-button {
  padding: 1rem 1.5rem;
  border: none;
  background: none;
  font-size: 1rem;
  color: #666;
  cursor: pointer;
  position: relative;
  transition: color 0.3s;
}

.tab-button:hover {
  color: #2c7be5;
}

.tab-button.active {
  color: #2c7be5;
  font-weight: 600;
}

.tab-button.active::after {
  content: '';
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 2px;
  background: #2c7be5;
}

.tab-content {
  padding: 2rem;
}

/* Overview Tab */
.overview-tab .user-stats {
  display: flex;
  gap: 2rem;
  flex-wrap: wrap;
}

.stat-card {
  background: #f8f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  min-width: 200px;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.stat-card h3 {
  margin: 0;
  color: #666;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.stat-value {
  margin: 0;
  font-size: 2rem;
  font-weight: 600;
  color: #2c7be5;
}

/* Activity Logs Tab */
.activity-logs-tab {
  max-height: 600px;
  overflow-y: auto;
}

.activity-log-item {
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: #fafafa;
}

.log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.log-type {
  font-weight: 600;
  color: #666;
  text-transform: capitalize;
  background: #e8e8e8;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.log-timestamp {
  color: #888;
  font-size: 0.9rem;
}

.log-changes {
  margin-top: 0.5rem;
}

.points-change, .coins-change {
  margin: 0.3rem 0;
  font-size: 0.95rem;
}

.previous-value {
  color: #888;
}

.new-value {
  color: #2c7be5;
  font-weight: 600;
}

/* Referral Tab */
.referral-tab {
  max-width: 600px;
  margin: 0 auto;
}

.activity-logs-section {
  margin-top: 2rem;
  background: #ffffff;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.activity-logs-section h3 {
  margin: 0 0 1rem 0;
  color: #333;
  font-size: 1.2rem;
}

.activity-logs {
  max-height: 400px;
  overflow-y: auto;
}

.activity-log-item {
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: #fafafa;
  padding: 1rem;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 1rem;
  background: #fafafa;
}

.log-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.log-type {
  font-weight: 600;
  color: #666;
  text-transform: capitalize;
  background: #e8e8e8;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #666;
  text-transform: capitalize;
  background: #e8e8e8;
  padding: 0.2rem 0.5rem;
  border-radius: 4px;
  font-size: 0.9rem;
}

.log-comment {
  color: #666;
  font-style: italic;
  margin: 0.5rem 0;
  padding: 0.5rem;
  background: #f0f0f0;
  border-radius: 4px;
}

.points-change, .coins-change {
  margin: 0.3rem 0;
  font-size: 0.95rem;
  margin: 0.3rem 0;
  font-size: 0.95rem;
}

.previous-value {
  color: #888;
  color: #888;
}

.new-value {
  color: #2c7be5;
  font-weight: 600;
  color: #2c7be5;
  font-weight: 600;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.02);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, 10px);
  }
  15% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  85% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
}

@keyframes dots {
  0%, 20% {
    content: '.';
  }
  40% {
    content: '..';
  }
  60%, 100% {
    content: '...';
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* QR Code Section */
.referral-qr-section {
  margin-top: 30px;
  padding: 20px;
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.referral-qr-section h3 {
  margin-bottom: 20px;
  color: #333;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  padding: 20px;
  background: white;
  border-radius: 8px;
}

.qr-code-info {
  color: #666;
  margin: 15px 0 5px;
  font-size: 14px;
}

.user-id-display {
  color: #333;
  font-size: 13px;
  font-family: monospace;
  background: #f5f5f5;
  padding: 8px;
  border-radius: 4px;
  margin: 0;
  word-break: break-all;
}

/* Stamp Collection Styles */
.stamp-collection-section {
  padding: 2rem;
  text-align: center;
}

.stamp-collection-section h3 {
  margin-bottom: 2rem;
  color: #333;
  font-size: 1.6rem;
}

.stamps-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1.5rem;
  max-width: 800px;
  margin: 0 auto 2rem;
  padding: 1rem;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.stamp-wrapper {
  position: relative;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.stamp-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.stamp-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  height: 80%;
  object-fit: contain;
  opacity: 0.85;
  transition: opacity 0.3s ease;
}

.stamp-wrapper:hover .stamp-overlay {
  opacity: 1;
}

.stamps-info {
  margin-top: 2rem;
  color: #555;
}

.stamps-info p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

.stamps-complete {
  color: #28a745;
  font-weight: bold;
}

.stamps-remaining {
  color: #666;
}

@media (max-width: 768px) {
  .stamps-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(4, 1fr);
    gap: 1rem;
    padding: 0.5rem;
  }
}
