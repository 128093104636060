.faqs-section {
  width: 100%;
  max-width: var(--dl-size-size-maxwidth);
  display: flex;
  padding: var(--dl-space-space-threeunits);
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #D4F0E2;
  border-radius: 20px;
  margin: 0 var(--dl-space-space-threeunits);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.faqs-container {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.faqs-header {
  width: 100%;
  display: flex;
  margin-bottom: var(--dl-space-space-fourunits);
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.faqs-title {
  color: #4A4A4A;
  font-size: 48px;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}

.faqs-subtitle {
  color: #4A4A4A;
  font-size: 18px;
  max-width: 600px;
  text-align: center;
  line-height: 1.5;
}

.faqs-list {
  width: 100%;
  display: flex;
  max-width: 900px;
  flex-direction: column;
  gap: var(--dl-space-space-unit);
}

.faq-item {
  width: 100%;
  cursor: pointer;
  padding: var(--dl-space-space-twounits);
  background: white;
  border-radius: 16px;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
}

.faq-item:hover {
  transform: translateY(-2px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

.faq-item.active {
  background: #f8f8f8;
}

.faq-question {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.faq-question h3 {
  color: #333;
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}

.faq-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon {
  width: 24px;
  height: 24px;
  fill: #666;
  transition: transform 0.3s ease;
}

.icon.rotate {
  transform: rotate(180deg);
}

.faq-answer {
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-answer.show {
  height: auto;
  opacity: 1;
  margin-top: var(--dl-space-space-unit);
}

.faq-answer p {
  color: #666;
  font-size: 16px;
  line-height: 1.6;
  margin: 0;
}

.faqs-contact {
  margin-top: var(--dl-space-space-fourunits);
  text-align: center;
}

.faqs-contact p {
  color: #4A4A4A;
  font-size: 18px;
  margin-bottom: var(--dl-space-space-unit);
}

.contact-button {
  display: inline-block;
  padding: 16px 32px;
  background-color: #6caeec;
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 24px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.contact-button:hover {
  background-color: #5a9de0;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(108, 174, 236, 0.3);
}

@media(max-width: 991px) {
  .faqs-section {
    padding: var(--dl-space-space-twounits);
  }
  
  .faqs-title {
    font-size: 40px;
  }
  
  .faqs-subtitle {
    font-size: 16px;
  }
}

@media(max-width: 767px) {
  .faqs-section {
    padding: var(--dl-space-space-unit);
  }
  
  .faqs-title {
    font-size: 32px;
  }
  
  .faq-question h3 {
    font-size: 18px;
  }
  
  .faq-answer p {
    font-size: 14px;
  }
}

@media(max-width: 479px) {
  .faqs-section {
    padding: var(--dl-space-space-unit);
  }
  
  .faqs-title {
    font-size: 28px;
  }
  
  .faq-item {
    padding: var(--dl-space-space-unit);
  }
}
