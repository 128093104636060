.navbar-container {
  width: 100%;
  display: flex;
  z-index: 100;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
  background-color: transparent;
}

.navbar-nav {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}

.navbar-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}

.navbar-icon {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 100;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}

.navbar-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.navbar-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}

.navbar-close-mobile-menu {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navbar-icon2 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}

.navbar-link {
  color: white;
  font-size: 18px;
  font-family: "Raleway";
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  transition: color 0.3s ease;
}

.navbar-link:hover {
  color: var(--dl-color-primary-pink);
}

.navbar-button {
  color: white;
  cursor: pointer;
  display: flex;
  transition: 0.3s;
  align-items: center;
  padding-top: 12px;
  margin-left: var(--dl-space-space-twounits);
  padding-left: 24px;
  border-radius: 50px;
  padding-right: 24px;
  padding-bottom: 12px;
  background-color: var(--dl-color-primary-pink);
  text-decoration: none;
}

.navbar-button:hover {
  transform: scale(1.02);
  background-color: var(--dl-color-primary-100);
}

.nav-container {
  display: flex;
  align-items: center;
}

.mobile-only {
  display: none;
}

@media (max-width: 991px) {
  .navbar-container {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}

@media (max-width: 767px) {
  .navbar-container {
    padding: var(--dl-space-space-unit);
  }
  .navbar-nav {
    display: none;
  }
  .mobile-only {
    display: flex;
    padding: 10px 20px;
    margin-right: 20px;
  }
  .navbar-burger-menu {
    display: flex;
  }
  .navbar-icon {
    fill: #D9D9D9;
  }
  .navbar-link {
    margin-left: var(--dl-space-space-unit);
  }
  .navbar-button {
    margin-left: var(--dl-space-space-unit);
  }
}

@media (max-width: 479px) {
  .navbar-container {
    padding: var(--dl-space-space-unit);
  }
  .navbar-mobile-menu {
    padding: 16px;
  }
  .navbar-link {
    font-size: 16px;
  }
}
