.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  background-color: transparent;
}

.home-video {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  bottom: 0;
  z-index: 0;
  position: absolute;
  object-fit: cover;
}

.home-video::after {
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  content: "";
  opacity: 0.6;
  position: absolute;
  background: rgba(255, 192, 203, 0.4);
  backdrop-filter: blur(2px);
}

.home-hero-content {
  gap: var(--dl-space-space-unit);
  width: 100%;
  height: 100%;
  display: flex;
  z-index: 1;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-sixunits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}

.home-header-container1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.home-heading10 {
  color: rgb(255, 255, 255);
  font-size: clamp(48px, 6vw, 72px);
  text-align: center;
  font-family: "Raleway";
  font-weight: 700;
  line-height: 1.1;
  margin-bottom: var(--dl-space-space-halfunit);
  text-transform: none;
  text-decoration: none;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
}

.home-caption10 {
  color: rgb(255, 255, 255);
  font-size: clamp(20px, 3vw, 24px);
  text-align: center;
  font-family: "Raleway";
  font-weight: 500;
  line-height: 1.5;
  text-transform: none;
  text-decoration: none;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}

.home-button1 {
  color: white;
  cursor: pointer;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  transition: 0.3s;
  align-items: center;
  padding-top: 1rem;
  padding-left: 2rem;
  border-radius: 52px;
  padding-right: 2rem;
  flex-direction: column;
  padding-bottom: 1rem;
  background-color: var(--dl-color-primary-pink);
  text-decoration: none;
}

.home-button1:hover {
  transform: scale(1.02);
  background-color: var(--dl-color-primary-100);
}

@media (max-width: 991px) {
  .home-hero-content {
    padding-left: var(--dl-space-space-threeunits);
    padding-right: var(--dl-space-space-threeunits);
  }
}

@media (max-width: 767px) {
  .home-hero-content {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
}

@media (max-width: 479px) {
  .home-hero-content {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
}
