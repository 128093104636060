.footer-section {
  width: 100%;
  padding: var(--dl-space-space-threeunits) var(--dl-space-space-fiveunits);
  background-color: #6caeec;
  color: white;
}

.footer-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  gap: var(--dl-space-space-twounits);
}

.social-links {
  display: flex;
  gap: var(--dl-space-space-twounits);
  margin-bottom: var(--dl-space-space-unit);
}

.social-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-wrapper {
  position: relative;
  z-index: 1;
  background: transparent;
}

.icon-wrapper a {
  display: block;
  text-decoration: none;
  background: transparent !important;
}

.icon-wrapper a:hover {
  background: transparent !important;
}

.social-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
  filter: none !important;
  mix-blend-mode: normal !important;
  isolation: isolate;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: var(--dl-space-space-halfunit);
}

.copyright {
  font-size: 14px;
  opacity: 0.9;
}

.footer-info {
  font-size: 14px;
  opacity: 0.8;
}

@media(max-width: 767px) {
  .footer-section {
    padding: var(--dl-space-space-twounits) var(--dl-space-space-unit);
  }
  
  .social-links {
    gap: var(--dl-space-space-unit);
  }
  
  .social-icon {
    width: 20px;
    height: 20px;
  }
}

@media(max-width: 479px) {
  .footer-section {
    padding: var(--dl-space-space-unit);
  }
  
  .social-links {
    gap: var(--dl-space-space-halfunit);
  }
}
