.reviews-section {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background: #FFFFFF;
  border-radius: 30px;
  margin: 0 var(--dl-space-space-threeunits);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.reviews-container {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
}

.reviews-title {
  color: #4A4A4A;
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  margin-bottom: 0;
  padding: var(--dl-space-space-twounits);
  background: #FFD4E5;
  border-radius: 15px;
  width: 100%;
  max-width: 900px;
}

.reviews-slider {
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-unit);
  align-items: center;
  justify-content: center;
  gap: var(--dl-space-space-unit);
  touch-action: pan-y pinch-zoom;
  user-select: none;
  -webkit-user-select: none;
}

.review-card {
  width: 100%;
  display: flex;
  max-width: 800px;
  min-height: 300px;
  transition: transform 0.3s ease-out;
  align-items: center;
  padding: var(--dl-space-space-twounits);
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  will-change: transform;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.review-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--dl-space-space-unit);
}

.review-stars {
  color: #ffd700;
  font-size: 24px;
  margin-bottom: var(--dl-space-space-unit);
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.review-text {
  color: #4A4A4A;
  font-size: 18px;
  line-height: 1.6;
  margin: 0;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 200px;
  padding-right: var(--dl-space-space-unit);
}

.review-text::-webkit-scrollbar {
  width: 6px;
}

.review-text::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.review-text::-webkit-scrollbar-thumb {
  background: #FFD4E5;
  border-radius: 3px;
}

.review-text::-webkit-scrollbar-thumb:hover {
  background: #FFB5D4;
}

.review-author {
  gap: var(--dl-space-space-halfunit);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.author-name {
  color: #4A4A4A;
  font-weight: 600;
  font-size: 18px;
}

.review-date {
  color: #666666;
  font-size: 14px;
}

.slider-button {
  width: 40px;
  height: 40px;
  border: none;
  cursor: pointer;
  display: flex;
  font-size: 24px;
  background: white;
  transition: all 0.3s ease;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.slider-button:hover {
  background: #FFD4E5;
  transform: scale(1.1);
}

.slider-dots {
  display: flex;
  margin-top: var(--dl-space-space-unit);
  gap: var(--dl-space-space-halfunit);
}

.dot {
  width: 10px;
  height: 10px;
  cursor: pointer;
  background: #D4D4D4;
  transition: all 0.3s ease;
  border-radius: 50%;
}

.dot.active {
  background: #FFD4E5;
  transform: scale(1.2);
}

@media(max-width: 991px) {
  .reviews-section {
    padding: var(--dl-space-space-threeunits);
  }
  
  .reviews-title {
    font-size: 40px;
  }
}

@media(max-width: 767px) {
  .reviews-slider {
    padding: var(--dl-space-space-halfunit);
    gap: var(--dl-space-space-halfunit);
  }
  
  .review-card {
    min-height: 200px;
    padding: var(--dl-space-space-unit);
  }
  
  .review-text {
    font-size: 16px;
    line-height: 1.4;
    max-height: 150px;
  }
  
  .slider-button {
    width: 32px;
    height: 32px;
    font-size: 18px;
    opacity: 0.8;
  }

  .review-stars {
    font-size: 20px;
  }

  .author-name {
    font-size: 16px;
  }

  .review-date {
    font-size: 12px;
  }

  .dot {
    width: 8px;
    height: 8px;
  }
}

@media(max-width: 479px) {
  .reviews-title {
    font-size: 24px;
    padding: var(--dl-space-space-unit);
  }
  
  .review-card {
    min-height: 180px;
  }
  
  .review-text {
    font-size: 14px;
    max-height: 120px;
  }
  
  .slider-button {
    width: 28px;
    height: 28px;
    font-size: 16px;
  }
}
