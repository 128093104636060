.home-container1 {
  width: 100%;
  display: flex;
  overflow: hidden;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  background: linear-gradient(135deg, #FFFFFF 0%, #FFFFFF 100%);
}
.home-hero {
  width: 100%;
  height: 100vh;
  display: flex;
  position: relative;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgba(255, 212, 229, 0.8);
  backdrop-filter: blur(5px);
}
.home-header10 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  z-index: 100;
  position: relative;
  flex-direction: row;
  justify-content: center;
  background: transparent;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 1000;
  position: relative;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  justify-content: space-between;
}
.home-branding1 {
  width: 120px;
  object-fit: cover;
}
.home-icon10 {
  width: 60px;
  object-fit: cover;
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  color: var(--dl-color-gray-white);
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 32px;
  z-index: 100;
  position: fixed;
  transform: translateX(100%);
  transition: 0.5s;
  flex-direction: column;
  justify-content: space-between;
  background-color: #eaa9a9;
}
.home-nav1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container2 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image10 {
  height: 2rem;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon11 {
  fill: var(--dl-color-gray-white);
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-nav2 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text11 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text12 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text13 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text14 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-container3 {
  flex: 0 0 auto;
  width: auto;
  display: flex;
  margin-top: var(--dl-space-space-unit);
  align-items: center;
  flex-direction: row;
}
.home-login {
  margin-right: var(--dl-space-space-twounits);
}
.home-icon-group {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  fill: var(--dl-color-gray-white);
}
.home-icon13 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon15 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
  margin-right: var(--dl-space-space-twounits);
}
.home-icon17 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0.5;
  filter: brightness(0.85);
}

@media (max-width: 991px) {
  .home-hero {
    padding-left: var(--dl-space-space-oneandhalfunits);
  }
  .home-hero-content {
    gap: var(--dl-space-space-threeunits);
  }
  .home-main {
    gap: var(--dl-space-space-threeunits);
    flex-direction: column;
  }
  .home-branding2 {
    width: 100%;
  }
}

@media (max-width: 767px) {
  .home-hero {
    background-color: rgba(255, 212, 229, 0.85);
    backdrop-filter: blur(3px);
  }
  .home-video {
    opacity: 0.4;
    filter: brightness(0.8) saturate(0.9);
  }
  .home-header-container {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-steps {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-header-container2 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-table {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-faq {
    gap: var(--dl-space-space-threeunits);
    padding-top: var(--dl-space-space-threeunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
}

@media (max-width: 479px) {
  .home-branding1 {
    padding-top: var(--dl-space-space-unit);
  }
  .home-hero {
    height: auto;
    padding-top: 120px;
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-hero-content {
    gap: var(--dl-space-space-threeunits);
  }
  .home-main {
    gap: var(--dl-space-space-threeunits);
    padding-bottom: var(--dl-space-space-sixunits);
  }
  .home-branding2 {
    gap: var(--dl-space-space-unit);
  }
  .home-text {
    font-size: 32px;
  }
  .home-caption {
    font-size: 16px;
    line-height: 24px;
  }
  .home-note {
    align-items: flex-start;
  }
  .home-content {
    gap: var(--dl-space-space-unit);
  }
  .home-main1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-header-container {
    gap: var(--dl-space-space-unit);
  }
  .home-header {
    gap: var(--dl-space-space-unit);
  }
  .home-heading {
    font-size: 32px;
  }
  .home-steps {
    gap: var(--dl-space-space-threeunits);
  }
  .home-step {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-heading11 {
    font-size: 32px;
  }
  .home-header-container2 {
    gap: var(--dl-space-space-unit);
  }
  .home-header12 {
    gap: var(--dl-space-space-unit);
  }
  .home-row10 {
    gap: var(--dl-space-space-unit);
  }
  .home-plans1 {
    gap: var(--dl-space-space-unit);
  }
  .home-row11 {
    gap: var(--dl-space-space-unit);
  }
  .home-row12 {
    gap: var(--dl-space-space-unit);
  }
  .home-plans2 {
    gap: var(--dl-space-space-unit);
  }
  .home-row13 {
    gap: var(--dl-space-space-unit);
  }
  .home-row14 {
    gap: var(--dl-space-space-unit);
  }
  .home-plans3 {
    gap: var(--dl-space-space-unit);
  }
  .home-row15 {
    gap: var(--dl-space-space-unit);
  }
  .home-row16 {
    gap: var(--dl-space-space-unit);
  }
  .home-plans4 {
    gap: var(--dl-space-space-unit);
  }
  .home-row17 {
    gap: var(--dl-space-space-unit);
  }
  .home-row18 {
    gap: var(--dl-space-space-unit);
  }
  .home-plans5 {
    gap: var(--dl-space-space-unit);
  }
  .home-row19 {
    gap: var(--dl-space-space-unit);
  }
}
.home-hero-content {
  gap: var(--dl-space-space-fiveunits);
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header-container1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header11 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-heading10 {
  color: white;
  font-size: 72px;
  font-weight: bold;
  position: relative;
  text-shadow: 
    2px 0 #FF69B4,
    -2px 0 #FF69B4,
    0 2px #FF69B4,
    0 -2px #FF69B4,
    1px 1px #FF69B4,
    -1px -1px #FF69B4,
    1px -1px #FF69B4,
    -1px 1px #FF69B4;
}
.home-caption10 {
  color: white;
  font-size: clamp(18px, 3vw, 24px);
  max-width: 600px;
  position: relative;
  text-shadow: 
    1.5px 0 #FF69B4,
    -1.5px 0 #FF69B4,
    0 1.5px #FF69B4,
    0 -1.5px #FF69B4,
    0.75px 0.75px #FF69B4,
    -0.75px -0.75px #FF69B4,
    0.75px -0.75px #FF69B4,
    -0.75px 0.75px #FF69B4;
}
.home-note {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-end;
  padding-top: 20px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 20px;
  justify-content: flex-start;
  background-color: #eaa9a9;
}
.home-caption11 {
  color: rgb(124, 124, 128);
  font-size: 40px;
  max-width: 800px;
  font-style: normal;
  font-weight: 400;
  line-height: 52px;
  margin-right: 158px;
}
.home-comparision {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  background: linear-gradient(135deg, #FFE6F3 0%, #F7E6FF 100%);
  border-radius: 30px;
  padding: var(--dl-space-space-fourunits);
  margin: 0 var(--dl-space-space-threeunits);
  margin-top: 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.home-header-container2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header12 {
  gap: 0;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-unit);
  flex-direction: column;
}
.home-heading11 {
  color: #4A4A4A;
  font-size: 3rem;
  text-align: center;
  font-weight: 600;
}

@media (max-width: 767px) {
  .home-heading11 {
    font-size: 2rem;
  }
}

@media (max-width: 479px) {
  .home-heading11 {
    font-size: 1.5rem;
  }
}

.home-table {
  width: 100%;
  display: flex;
  padding: 20px;
  max-width: 900px;
  background: white;
  border-radius: 20px;
  flex-direction: column;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
}
.home-row10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-plans1 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row11 {
  width: 100%;
  display: flex;
  padding: 20px;
  background: #FFD4E5;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 15px;
  justify-content: space-between;
}
.home-text22 {
  color: #4A4A4A;
  width: 33.33%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  padding: 10px;
}
.home-text23 {
  color: #4A4A4A;
  width: 33.33%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  padding: 10px;
}
.home-text24 {
  color: #4A4A4A;
  width: 33.33%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  padding: 10px;
}
.home-row12 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-plans2 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row13 {
  width: 100%;
  display: flex;
  padding: 20px;
  background: #FFFFFF;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 10px;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.home-text25 {
  color: #FF69B4;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 600;
}
.home-text26 {
  color: #9B6B9W;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}
.home-text27 {
  color: #4A90E2;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}
.home-row14 {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: flex-start;
  animation-name: none;
  flex-direction: row;
  animation-delay: 0s;
  animation-duration: 300ms;
  animation-direction: normal;
  animation-iteration-count: 1;
  animation-timing-function: ease;
}
.home-plans3 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row15 {
  width: 100%;
  display: flex;
  padding: 20px;
  background: #FFFFFF;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 10px;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.home-text28 {
  color: #FF69B4;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 600;
}
.home-text29 {
  color: #9B6B9W;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}
.home-text30 {
  color: #4A90E2;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}
.home-row16 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-plans4 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row17 {
  width: 100%;
  display: flex;
  padding: 20px;
  background: #FFFFFF;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 10px;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.home-text31 {
  color: #FF69B4;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 600;
}
.home-text32 {
  color: #9B6B9W;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}
.home-text33 {
  color: #4A90E2;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}
.home-row18 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-plans5 {
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-row19 {
  width: 100%;
  display: flex;
  padding: 20px;
  background: #FFFFFF;
  align-items: center;
  border-radius: 15px;
  margin-bottom: 10px;
  justify-content: space-between;
  transition: all 0.3s ease;
}
.home-text34 {
  color: #FF69B4;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 600;
}
.home-text35 {
  color: #9B6B9W;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}
.home-text36 {
  color: #4A90E2;
  width: 100%;
  font-size: 18px;
  text-align: center;
  line-height: 1.5;
  font-weight: 500;
}
.home-header-container3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: var(--dl-space-space-fiveunits);
}
.home-header13 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-heading12 {
  color: #000000;
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-google-maps {
  width: 989px;
  height: 624px;
  padding-left: var(--dl-space-space-halfunit);
  padding-right: var(--dl-space-space-halfunit);
}
.home-statistics {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 40px;
  background-color: #D4E5FF;
  border-radius: 20px;
  padding: var(--dl-space-space-threeunits);
  margin: var(--dl-space-space-threeunits);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.home-heading13 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-content10 {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: flex-start;
}
.home-stat1 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-image11 {
  width: 200px;
  height: 171px;
  object-fit: cover;
}
.home-header14 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-style: italic;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 500;
  line-height: 73px;
}
.home-caption12 {
  color: rgb(124, 124, 128);
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-stat2 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-image12 {
  width: 200px;
  object-fit: cover;
}
.home-header15 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-style: italic;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 500;
  line-height: 73px;
}
.home-caption13 {
  color: rgb(124, 124, 128);
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-stat3 {
  gap: var(--dl-space-space-oneandhalfunits);
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
}
.home-image13 {
  width: 193px;
  height: 182px;
  object-fit: cover;
}
.home-header16 {
  color: rgb(255, 255, 255);
  font-size: 30px;
  font-style: italic;
  text-align: center;
  font-family: "Playfair Display";
  font-weight: 500;
  line-height: 73px;
}
.home-caption14 {
  color: rgb(124, 124, 128);
  margin-left: var(--dl-space-space-oneandhalfunits);
  margin-right: var(--dl-space-space-oneandhalfunits);
}
.home-locations {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-color: #FFF4D4;
  border-radius: 20px;
  padding: var(--dl-space-space-threeunits);
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.home-google-maps {
  width: 100%;
  height: 450px;
  border-radius: 24px;
}
.home-faq {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-fiveunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  background-color: #D4F0E2;
  border-radius: 20px;
  padding: var(--dl-space-space-threeunits);
  margin-left: var(--dl-space-space-threeunits);
  margin-right: var(--dl-space-space-threeunits);
  margin-bottom: 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}
.home-header22 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-heading19 {
  color: rgb(255, 255, 255);
  font-size: 60px;
  font-style: normal;
  font-weight: 400;
}
.home-text51 {
  font-style: italic;
  font-family: Playfair Display;
  font-weight: 500;
}
.home-content15 {
  gap: 120px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  justify-content: flex-start;
}
.home-column1 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-element1 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-header23 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content16 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element2 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-header24 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content17 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element3 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-header25 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content18 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-column2 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 640px;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-element4 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-header26 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content19 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element5 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-header27 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content20 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-element6 {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
  justify-content: flex-start;
}
.home-header28 {
  color: rgb(255, 255, 255);
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
}
.home-content21 {
  color: rgb(124, 124, 128);
  width: 100%;
  display: flex;
  max-width: 560px;
  line-height: 24px;
}
.home-google-maps {
  width: 100%;
  height: 450px;
  margin-bottom: var(--dl-space-space-twounits);
}
.new-tag {
  color: #ff0000;
  font-style: italic;
  margin-left: 4px;
  font-size: inherit;
}
.home-nav {
  display: flex;
  align-items: center;
  gap: var(--dl-space-space-twounits);
}

.nav-link {
  color: white;
  text-decoration: none;
  transition: 0.3s;
  margin-right: var(--dl-space-space-unit);
}

.nav-link:last-child {
  margin-right: 0;
}

.nav-link:hover {
  color: #6caeec;
}

@media(max-width: 767px) {
  .home-nav {
    display: none;
  }
}

.home-nav2 {
  gap: var(--dl-space-space-unit);
}

.home-nav2 .nav-link {
  color: white;
  display: block;
  padding: var(--dl-space-space-unit);
  text-decoration: none;
}

.home-nav2 .nav-link:hover {
  background: rgba(108, 174, 236, 0.1);
  color: #6caeec;
}

.nav-button {
  background: linear-gradient(135deg, #FF69B4 0%, #FF1493 100%);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  font-size: 14px;
}

.nav-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 105, 180, 0.3);
}

.nav-button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(255, 105, 180, 0.2);
}

.home-section {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  padding: var(--dl-space-space-fourunits);
  background: #f6f7ff;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  margin: 0 var(--dl-space-space-threeunits);
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.home-comparision {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  padding: var(--dl-space-space-fourunits);
  background: linear-gradient(135deg, #FFE6F3 0%, #F7E6FF 100%);
  border-radius: 30px;
  margin: 0 var(--dl-space-space-threeunits);
  margin-top: 0;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

@media(max-width: 991px) {
  .home-section,
  .home-comparision {
    padding: var(--dl-space-space-threeunits);
    margin: 0 var(--dl-space-space-unit);
  }
}

@media(max-width: 767px) {
  .home-section,
  .home-comparision {
    padding: var(--dl-space-space-twounits);
    margin: 0 calc(var(--dl-space-space-halfunit));
  }
}

@media(max-width: 479px) {
  .home-section,
  .home-comparision {
    width: 100%;
    padding: var(--dl-space-space-unit);
    margin: 0;
    border-radius: 20px;
  }
}

.home-button,
.home-link,
.interactive-element {
  transition: all 0.3s ease;
}

.home-button:hover,
.home-link:hover,
.interactive-element:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.home-section {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  padding: var(--dl-space-space-fourunits);
  background: #f6f7ff;
  align-items: center;
  flex-direction: column;
  border-radius: 30px;
  margin: 0 var(--dl-space-space-threeunits);
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.home-header-container {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  flex-direction: column;
  padding-bottom: var(--dl-space-space-threeunits);
}

.home-header {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-heading {
  color: #4A4A4A;
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  line-height: 1.2;
}

.home-steps {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: flex-start;
  padding-top: var(--dl-space-space-twounits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: center;
}

.home-step {
  flex: 1;
  display: flex;
  padding: var(--dl-space-space-twounits);
  max-width: 350px;
  align-items: center;
  flex-direction: column;
  background-color: white;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.home-step:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
}

.home-step-number {
  width: 48px;
  height: 48px;
  display: flex;
  font-size: 24px;
  margin-top: -40px;
  align-items: center;
  font-weight: 600;
  border-radius: 50%;
  margin-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
  background-color: #FFD4E5;
  color: #4A4A4A;
}

.home-step-content {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}

.home-step-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: var(--dl-space-space-unit);
}

.home-step h3 {
  color: #4A4A4A;
  font-size: 24px;
  text-align: center;
  font-weight: 600;
  margin-bottom: var(--dl-space-space-unit);
}

.home-step p {
  color: #666666;
  font-size: 16px;
  text-align: center;
  line-height: 1.6;
}

@media(max-width: 991px) {
  .home-steps {
    padding: var(--dl-space-space-twounits);
  }
  
  .home-heading {
    font-size: 36px;
  }
  
  .home-step {
    max-width: 300px;
  }
}

@media(max-width: 767px) {
  .home-section {
    padding: var(--dl-space-space-twounits);
  }
  
  .home-steps {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
    align-items: center;
  }
  
  .home-step {
    width: 100%;
    max-width: 400px;
  }
  
  .home-heading {
    font-size: 32px;
  }
  
  .home-step h3 {
    font-size: 20px;
  }
  
  .home-step p {
    font-size: 14px;
  }
}

@media(max-width: 479px) {
  .home-section {
    padding: var(--dl-space-space-unit);
  }
  
  .home-steps {
    padding-top: var(--dl-space-space-unit);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-unit);
  }
  
  .home-step {
    padding: var(--dl-space-space-oneandhalfunits);
  }
  
  .home-heading {
    font-size: 28px;
  }
  
  .home-step h3 {
    font-size: 18px;
  }
  
  .home-step p {
    font-size: 12px;
  }
}

.home-contact-info {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: var(--dl-space-space-unit);
  margin-bottom: var(--dl-space-space-twounits);
  padding: var(--dl-space-space-unit);
  background: white;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.home-contact-info p {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  color: #4A4A4A;
}

.home-contact-info strong {
  color: #000000;
  font-weight: 600;
}

.home-row13:hover, .home-row15:hover, .home-row17:hover, .home-row19:hover {
  background: #f8f9fa;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.home-heading11 {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
}

.heading-icon-left {
  color: #FFD700;
  font-size: 36px;
  margin-right: 8px;
}

.heading-icon-right {
  color: #FFD700;
  font-size: 24px;
  margin-left: 4px;
  animation: twinkle 1.5s infinite;
}

.heading-icon-right:nth-child(3) {
  animation-delay: 0.5s;
}

.heading-icon-right:nth-child(4) {
  animation-delay: 1s;
}

@keyframes twinkle {
  0% { opacity: 1; }
  50% { opacity: 0.3; }
  100% { opacity: 1; }
}

.home-heading {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
}

.home-heading .heading-icon-left,
.home-heading .heading-icon-right {
  color: #FFD700;
  font-size: 32px;
  transition: all 0.3s ease;
}

.home-heading:hover .heading-icon-left {
  transform: rotate(-15deg) scale(1.1);
  color: #FFB700;
}

.home-heading:hover .heading-icon-right:nth-of-type(1) {
  transform: translateY(-5px) scale(1.1);
  color: #FFB700;
}

.home-heading:hover .heading-icon-right:nth-of-type(2) {
  transform: translateY(-5px) rotate(15deg) scale(1.1);
  color: #FFB700;
}

@keyframes float {
  0% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

.heading-icon-right {
  animation: float 2s ease-in-out infinite;
}

.heading-icon-right:nth-of-type(2) {
  animation-delay: 0.5s;
}

.social-link {
  color: #6caeec;
  text-decoration: none;
  transition: all 0.3s ease;
}

.social-link:hover {
  color: #5a9de0;
  transform: translateY(-2px);
}

.home-icon {
  font-size: 24px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.xiaohongshu-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.home-icon-group {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  fill: var(--dl-color-gray-white);
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.social-link:hover {
  transform: translateY(-2px);
}

.social-icon {
  width: 28px;
  height: 28px;
  object-fit: contain;
  filter: brightness(0) saturate(100%) invert(67%) sepia(16%) saturate(2103%) hue-rotate(182deg) brightness(94%) contrast(87%);
  transition: all 0.3s ease;
}

.social-link:hover .social-icon {
  filter: brightness(0) saturate(100%) invert(62%) sepia(14%) saturate(1873%) hue-rotate(182deg) brightness(88%) contrast(87%);
}

.home-button1 {
  display: inline-block;
  padding: 16px 32px;
  background-color: #6caeec !important;
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-size: 18px;
  font-weight: 600;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.home-button1:hover {
  background-color: #5a9de0 !important;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(108, 174, 236, 0.3);
}
