.auth-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  background: linear-gradient(135deg, #f5f5f5 0%, #f7e6ff 100%);
}

.auth-box {
  background: white;
  padding: 40px;
  border-radius: 20px;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
}

.auth-box h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 24px;
}

.auth-message {
  background-color: #f8f9fa;
  border: 1px solid #dee2e6;
  border-radius: 8px;
  padding: 12px;
  margin-bottom: 20px;
  color: #495057;
  text-align: center;
  font-size: 14px;
}

form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;
}

input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 16px;
  transition: border-color 0.2s ease;
}

input:focus {
  outline: none;
  border-color: #ff9ecd;
}

button {
  background: linear-gradient(135deg, #ff9ecd 0%, #ff8ac3 100%);
  color: white;
  border: none;
  padding: 12px;
  border-radius: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(255, 105, 180, 0.3);
}

button:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(255, 105, 180, 0.2);
}

.google-btn {
  background: white;
  color: #333;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 12px;
  position: relative;
}

.google-btn:hover {
  background: #f8f8f8;
}

.google-icon {
  width: 18px;
  height: 18px;
  position: absolute;
  left: 12px;
}

.google-btn span {
  width: 100%;
  text-align: center;
}

p {
  text-align: center;
  color: #666;
  margin: 0;
}

.auth-link {
  color: #ff9ecd;
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
}

.auth-link:hover {
  text-decoration: underline;
  color: #ff8ac3;
}

.forgot-password {
  font-size: 12px;
  text-align: right;
  margin-top: -15px;
  margin-bottom: 15px;
}

.forgot-password a {
  color: #666;
  text-decoration: none;
}

.forgot-password a:hover {
  text-decoration: underline;
}

.signup-options {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.signup-method {
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
}

.signup-method h3 {
  color: #333;
  font-size: 16px;
  margin: 0 0 15px 0;
  text-align: center;
}

.or-divider {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 10px 0;
}

.or-divider::before,
.or-divider::after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #ddd;
}

.or-divider span {
  padding: 0 15px;
  color: #666;
  font-size: 14px;
  text-transform: uppercase;
}

.referral-section {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.referral-note {
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
}

.referral-section input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.referral-section input::placeholder {
  color: #999;
}

/* Referral Banner */
.referral-banner {
  background: #f8f4ff;
  border: 1px solid #e6d8ff;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.referral-icon {
  font-size: 24px;
}

.referral-message {
  flex: 1;
}

.referral-message p {
  margin: 0;
  text-align: left;
}

.referral-code {
  color: #6b4fbb;
  font-weight: 500;
}

@media (max-width: 480px) {
  .auth-box {
    padding: 20px;
  }
  
  .auth-box h2 {
    font-size: 20px;
    margin-bottom: 20px;
  }
  
  input {
    padding: 10px;
    font-size: 14px;
  }
  
  button {
    padding: 10px;
    font-size: 14px;
  }
}
